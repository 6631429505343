import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.css']
})
export class NosotrosComponent implements OnInit {


  //Obtener la altura de la pantalla actual, de cada sección en base a la altura del dispositivo
  innerHeight: any;
  innerWidth: any;
  imageHorizonte: any;
  imageEnviosEnHoras: any;
  imageBus: any;
  baseImagesPath: string = '/assets/img/';

  constructor()
    {
    this.innerHeight = (window.innerHeight);
    //this.innerWidth = (window.innerWidth) + "px";

    this.imageHorizonte = this.baseImagesPath + 'imgNosotros.png';
    this.imageEnviosEnHoras = this.baseImagesPath + 'imgPrincipal.png';
    this.imageBus = this.baseImagesPath + 'imgBus.png';
  }
  
  ngOnInit() {
  }

}
