import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-titulo-section',
  templateUrl: './titulo-section.component.html',
  styleUrls: ['./titulo-section.component.css']
})
export class TituloSectionComponent {
  @Input() tituloseccion : string; 
}
