import { Component, OnInit, SecurityContext, HostListener,ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { GuiaEntity } from '../../../../entidades/guiaEntity';
import { GestionGuiasService } from '../../../../servicios/gestion-guias.service';
import { Prefijos } from '../../../../helpers/prefijos';
import { OrigDestiArray } from '../../../../helpers/orig_dest';
import { Mensaje } from '../../../../helpers/mensajes';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
  providers: [GestionGuiasService],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class InicioComponent implements OnInit {
  public innerWidth: any;
  public contenedorImagenHeight:number;

  public diffLista:any[];
  public respaldoLista: any[];
  public visibleAlerta: boolean;
  public guiasSinInfo: boolean;
  public loading: boolean = false;

  public consultaCorrectaFlag: boolean = false;
  public consultaTokenCorrectaFlag: boolean = false;
  public innerHeight: number;

  public baseImagesPath: string = 'assets/img/';
  public baseEstatusImagePath: string = this.baseImagesPath + 'estatusGuias/';
  public imageBus: string;
  public imageEnviosEnHoras: string;
  public imageHorizonte: string;

  public listaGuias: GuiaEntity[] = new Array<GuiaEntity>();
  public rastreoForm: FormGroup;
  public listaRastreo: any = [];

  //Ciudades Origenes-Destinos
  public origenes = OrigDestiArray.allOrigDest;
  public destinos = OrigDestiArray.allOrigDest;
  public clasificaciones = Prefijos.clasificaciones;

  private get window():any {
    return window;
  }

  private get document():any {
    return window.document;
  }

  public status: any = {
    isFirstOpen: true,
    isOpen: false
  };

  public alertMsg: any = {
    type: null,
    msg: null
  };

  public guia = {
    prefijo: null,
    numero: null,
    origen: null,
    destino: null
  };

  /**
  Tipos de mensaje de respuesta a la consulta del rastreo de guias.
  **/
  private alerts = Mensaje.alertasRastreo;

  constructor(private elRef:ElementRef,
    private gestionGuiasService: GestionGuiasService,
    private formBuilder: FormBuilder) {

    this.innerHeight = (window.innerHeight);
    this.imageHorizonte = this.baseImagesPath + 'imgHorizonteInicio.png';
    this.imageEnviosEnHoras = this.baseImagesPath + 'imgPrincipal.png';
    this.imageBus = this.baseImagesPath + 'imgBus.png';
    this.innerWidth=(window.innerWidth);
    this.contenedorImagenHeight=100;

  }

  ngOnInit() {

    this.guia.numero='';
    
    /**
     * Se generan los validadores para el formulario.
     */
    this.rastreoForm = new FormGroup({
      prefijo: new FormControl(),
      numero: new FormControl(),
      origen: new FormControl(),
      destino: new FormControl()
    });


    this.rastreoForm = this.formBuilder.group({
      prefijo: [null, Validators.required],
      origen: [null, Validators.required],
      destino: [null, Validators.required],
      numero: [null, Validators.compose(
        [Validators.required,
        Validators.minLength(1),
        Validators.maxLength(15),
        Validators.pattern(/^[\d]+$/)])]
    });
  }

  //Se escucha el evento de onResize
  ngAfterViewInit(){
    this.innerHeight = (window.innerHeight);
    this.innerWidth = (window.innerWidth);
    setTimeout(() => this.imprimePosicion(), 0);
  }

  //Se escucha el evento de onResize
  onResize(event) {
    //event.target.innerWidth // window width
    this.innerHeight = (event.target.innerHeight)
    this.innerWidth=(window.innerWidth);
    setTimeout(() => this.imprimePosicion(), 0);
  }


  /**
   * Metodo para agregar un nuevo elemento de Tipo Guia a la lista (listaGuias[])
   */
  public addGuia(guiaActual: any) {
    let localPrefijo = {
      "id": 0,
      // "abreviatura": "PP",
      "abreviatura": guiaActual.prefijo,
      "nombre": ""
    };

    let localOrigen = {
      "id": 0,
      "abreviatura": guiaActual.origen,
      "nombre": ""
    };

    let localDestino = {
      "id": 0,
      "abreviatura": guiaActual.destino,
      "nombre": ""
    };

    if (localOrigen.abreviatura == localDestino.abreviatura && localPrefijo.abreviatura === "ENVIO") {
      alert('No se permite consultar guías con el mismo origen y destino');
      this.rastreoForm.reset();
    } else {
      let nuevaGuia = new GuiaEntity(0, guiaActual.numero, localPrefijo, localOrigen, localDestino);
      let duplicate = false;

      /*Se valida si se esta ingresando una guia duplicada, en el caso de que sea asi, no se agrega a la lista
      Y se envia un mensaje de alerta.*/
      this.listaGuias.forEach(element => {
        if (element.prefijo.abreviatura === nuevaGuia.prefijo.abreviatura && element.folio === nuevaGuia.folio &&
          element.origen.abreviatura === nuevaGuia.origen.abreviatura && element.destino.abreviatura === nuevaGuia.destino.abreviatura) {
          duplicate = true;
        }
      });


      if (duplicate) {
        alert('No se permite consultar guias duplicadas');
        this.rastreoForm.controls.destino.enable();
        this.rastreoForm.reset();
      } else if (this.listaGuias.length >= 3) {
        alert('Solo se pueden consultar un máximo de 3 Guías a la vez');
        this.rastreoForm.controls.destino.enable();
        this.rastreoForm.reset();
      } else {
        this.listaGuias.push(new GuiaEntity(0, guiaActual.numero, localPrefijo, localOrigen, localDestino));
        this.rastreoForm.controls.destino.enable();
        this.guia.destino = "";
        this.rastreoForm.reset();
      }

    }
  }

  /**
   * Se remueven elementos de tipo GuiaEntity, del text Area y se reordena el Array del mismo.
   * @param mGuia guia a eliminar del Text Area
   */
  public removeGuia(mGuia: GuiaEntity) {
    const index: number = this.listaGuias.indexOf(mGuia);
    if (index !== -1) {
      this.listaGuias.splice(index, 1);
    }
  }


  /**
   * Se envia el conjunto de guias a Rastrear por medio del Servicio
   */
  public sendGuia() {

    this.respaldoLista = this.listaGuias;
    this.loading = true;
    //se obtiene el arreglo de estatus de las guias consultadas desde el servicio.
    this.gestionGuiasService.getRastreoGuiasFolioOrigenDestino(this.listaGuias,"RASTREO")
      .then(res => {
        this.loading = false;
        this.alertMsg = this.alerts.success;
        //Se accede al objeto lista de la respuesta.
        if (res['bitacorasGuias']['bitacoraGuia']) {
          let listTemp=res['bitacorasGuias']['bitacoraGuia'];
          listTemp = listTemp.filter(rastreoGuia => rastreoGuia.guia.estatus.abreviatura !== "CA");
          
          if (listTemp.length > 0) {
            
            listTemp.forEach(element => {
              let cadena=element.fechaModificacion;
              element.fechaModificacion=this.GetFormattedDate(cadena);  
            });
            
            this.listaRastreo = listTemp;
            if (this.respaldoLista.length > this.listaRastreo.length) {
              this.guiasSinInfo = true;
  
              //Se homogeniza la lista de consulta a {guia:prefijo+folio}
              let listaTempConsulta: {
                 guia : string
                }[] = []
              this.respaldoLista.forEach(element => {
                let cadena={
                  guia:element.prefijo.abreviatura + '-' + element.folio
                }              
                listaTempConsulta.push(cadena);
              });
              //Se homogeniza la lista de respuesta a {guia:prefijo+folio}
              let listaTempRespuesta: { guia : string}[] = []
              this.listaRastreo.forEach(element => {
                let cadena={
                  guia: element.guia.prefijo.abreviatura + '-' + element.guia.folio
                  // guia: "PP" + '-' + element.guia.folio
                }
                listaTempRespuesta.push(cadena);
              });
              //Se obtiene la diferencia de los dos arrays, el original y el de la respuesta
              this.diffLista=this.getDiffOfArrays(listaTempConsulta,listaTempRespuesta);
              this.listaGuias = [];
              this.respaldoLista=[];
            } else {
              this.listaGuias = [];
              this.respaldoLista=[];
              this.guiasSinInfo = false;
            }
            this.listaGuias = [];
            this.respaldoLista=[];
            this.consultaCorrectaFlag=true;
            this.rastreoForm.reset();
            
          }else{
            this.visibleAlerta = true;
            this.alertMsg = this.alerts.info;
            this.listaGuias = [];
            this.respaldoLista=[];
          }

         
        } else {
          this.visibleAlerta = true;
          this.alertMsg = this.alerts.info;
          this.listaGuias = [];
          this.respaldoLista=[];
        }
      })
      .catch(err => {
        //alert('Error======>'+JSON.stringify(err));
        this.loading = false;
        this.visibleAlerta = true;
        this.alertMsg = this.alerts.fail;
        this.setDefaultForm();
      });
      
  }

    /**
   * Se envia el conjunto de guias a Rastrear por medio del Servicio
   */
  public sendGuiaForToken() {

    this.respaldoLista = this.listaGuias;
    this.loading = true;
    //se obtiene el arreglo de estatus de las guias consultadas desde el servicio.
    this.gestionGuiasService.getRastreoGuiasFolioOrigenDestino(this.listaGuias,"TOKEN")
      .then(res => {
        this.loading = false;
        this.alertMsg = this.alerts.success;
        //Se accede al objeto lista de la respuesta.
        if (res['bitacorasGuias']['bitacoraGuia']) {
          let listTemp=res['bitacorasGuias']['bitacoraGuia'];
          
          listTemp.forEach(element => {
            let cadena=element.fechaModificacion;
            if(cadena!=null){
              element.fechaModificacion=this.GetFormattedDate(cadena);  
            }
            
          });
          
          this.listaRastreo = listTemp;
          if (this.respaldoLista.length > this.listaRastreo.length) {
            this.guiasSinInfo = true;

            //Se homogeniza la lista de consulta a {guia:prefijo+folio}
            let listaTempConsulta: { guia : string}[] = []
            this.respaldoLista.forEach(element => {
              let cadena={
                guia:element.prefijo.abreviatura + '-' + element.folio
              }              
              listaTempConsulta.push(cadena);
            });
            //Se homogeniza la lista de respuesta a {guia:prefijo+folio}
            let listaTempRespuesta: { guia : string}[] = []
            this.listaRastreo.forEach(element => {
              let cadena={
                guia:element.guia.prefijo.abreviatura + '-' + element.guia.folio
                // guia:"PP" + '-' + element.guia.folio
              }
              listaTempRespuesta.push(cadena);
            });
            //Se obtiene la diferencia de los dos arrays, el original y el de la respuesta
            this.diffLista=this.getDiffOfArrays(listaTempConsulta,listaTempRespuesta);
            this.listaGuias = [];
            this.respaldoLista=[];
          } else {
            this.listaGuias = [];
            this.respaldoLista=[];
            this.guiasSinInfo = false;
          }
          this.listaGuias = [];
          this.respaldoLista=[];
          this.consultaTokenCorrectaFlag=true;
          this.rastreoForm.reset()
        } else {
          this.visibleAlerta = true;
          this.alertMsg = this.alerts.info;
          this.listaGuias = [];
          this.respaldoLista=[];
        }
      })
      .catch(err => {
        this.loading = false;
        this.visibleAlerta = true;
        this.alertMsg = this.alerts.fail;
        this.setDefaultForm();
      });
      
  }

  //Se setea con los controles del formulario por default.
  private setDefaultForm() {
    this.consultaCorrectaFlag = false;
    this.consultaTokenCorrectaFlag=false;
    this.listaGuias = [];
    this.respaldoLista=[];
    this.diffLista=[];
  }

  public onAlertClose(): void {
    this.visibleAlerta = false;
  }

  private getDiffOfArrays(result1,result2){
          //Find values that are in result1 but not in result2
      var uniqueResultOne = result1.filter(function(obj) {
        return !result2.some(function(obj2) {
            return obj.guia == obj2.guia;
        });
      });

      //Find values that are in result2 but not in result1
      var uniqueResultTwo = result2.filter(function(obj) {
        return !result1.some(function(obj2) {
            return obj.guia == obj2.guia;
        });
      });

      //Combine the two arrays of unique entries
      var result = uniqueResultOne.concat(uniqueResultTwo);
      result.forEach(e => {
        e.guia = e.guia.replace(/ENVIO-|ALMACENAJE-|ABONO-|/, "Guía: ");
      });
      return result;
  }

  
private  GetFormattedDate(myDate) {
  //myDate=YYYY-mm-dd hh:mm:ss.ff
  let miFecha=myDate.split(" ");
  let fechaSeparada=miFecha[0].split("-");
  let fechaLista=fechaSeparada[2]+"-"+fechaSeparada[1]+"-"+fechaSeparada[0];
  return fechaLista;
}

//Valida que tenga maximo 15 numeros, y que no se introduzcan signos + ó -
validateNumero(value,localGuia)  {
  // if(value.keyCode===43 || value.keyCode===45 || localGuia>99999999999999){
  if(value.keyCode===13){
    value.preventDefault();
  }
}

/**
   * Provides read-only equivalent of jQuery's offset function:
   * http://api.jquery.com/offset/
   */
  private offset(nativeEl:any):{width: number, height: number, top: number, left: number} {
    let boundingClientRect = nativeEl.getBoundingClientRect();
    return {
      width: boundingClientRect.width || nativeEl.offsetWidth,
      height: boundingClientRect.height || nativeEl.offsetHeight,
      top: boundingClientRect.top + (this.window.pageYOffset || this.document.documentElement.scrollTop),
      left: boundingClientRect.left + (this.window.pageXOffset || this.document.documentElement.scrollLeft)
    };
  }

  public posBusBottom:number=0;

  imprimePosicion(){
    let divTop = this.offset(this.elRef.nativeElement.querySelector('#busImage'));
    this.posBusBottom=this.innerHeight*.9;
    let topSumDivs=divTop.height;
    //this.contenedorImagenHeight=Math.abs(Math.abs(this.innerHeight-topSumDivs));
    this.contenedorImagenHeight=Math.abs(Math.abs(this.innerHeight));
  }

  validarTipoGuia(){
    
    if(this.guia.prefijo !== null){
      if(this.guia.prefijo === "ENVIO"){
        this.rastreoForm.controls.destino.enable();
      }else{
        this.rastreoForm.controls.destino.disable();
        this.guia.destino = "";
      }

    }

  }


}
