import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-politica',
  templateUrl: './modal-politica.component.html',
  styleUrls: ['./modal-politica.component.css']
})
export class ModalPoliticaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
