import { Component, Input, ViewEncapsulation, HostListener, ViewChild,ElementRef, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MnFullpageOptions } from 'ngx-fullpage';
import { Sucursales } from '../../../helpers/Sucursales';
import { ServiceServices } from '../../../servicios/service.service';
import { RegistroConcursoService } from '../../../servicios/registro-concurso.service';
import { DatosConcurso } from '../../../entidades/datosconcurso';
//import the file uploader plugin
import { environment } from '../../../../environments/environment';
import { Mensaje } from '../../../helpers/mensajes';
import * as rxjs from 'rxjs';

//Ruta para enviar el formulario al servidor, referente al registro del concurso.
const URL = environment.CONCURSO_MANDA_GUIA;

@Component({
  selector: 'app-principal',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css'],
  providers: [ServiceServices, RegistroConcursoService],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class PrincipalComponent {

  contenedorImagenContratoHeight: number;
  public visibleAlerta: boolean = false;
  public loading:boolean=false;
  options : any;

  booValorAutoScroll: boolean=true;
  @ViewChild('Imagen')
  myInputFile: any;
  /**
  Tipos de mensaje de respuesta del registro al concurso.
  **/
  private alertsC = Mensaje.alertasConcurso;
  public fileName: string;

  public alertMsgC: any = {
    type: null,
    msg: null
  };

  //Logica para el concurso  
  public datosConcurso = new DatosConcurso('', '', '');

  public concursoForm: FormGroup;
  public innerHeight: number;
  public innerWidth: number;
  contenedorImagenHeight:number;
  //Datos de sucursales
  public lat: number = 21.122647;
  public lng: number = -101.6545509;
  public estadosType = [];
  public municipioType = [];
  public sucursalType = [];
  public direccionType = [];
  //direccionType: [{lat: 21.122647, ing: -101.6545509}];
  public baseImagesPath: string = 'assets/img/';
  public imageLogo: string = this.baseImagesPath + 'logo_egfa1.png';
  public callCenter:string =this.baseImagesPath+'callCenter.png';
  public estadoAfterChangeEvent = [];
  public municipioAfterChangeEvent = [];
  public sucursalAfterChangeEvent = [];
  public estadoForm;


  public localizacionMapa = {
    estado: "Guanajuato",
    municipio: "León",
    sucursal: "León Central de Autobuses",
    direccion: "Blvd. Hilario Medina No. 202 Int. Central de Autobuses",
    telefono: '477 710 00 60',
    horario: 'Lunes a Viernes de 9:00 a 17:00 hrs.'
  };

  //Datos de cobertura
  public imageSatelite: string;
  public imageHorizonte: string;
  public objEstado = Sucursales.estadosMType;
  public objMunicipio = Sucursales.allMunicipios;
  public objSucursal = Sucursales.allSucursales;
  public objDatos = Sucursales.allDatos;
  public Municipio;
  public Estado;
  public Indice;

  imageConcurso: string = this.baseImagesPath + 'mandaTuGuia.png';
  imageContrato: string = this.baseImagesPath + 'contrato.png';
  imageMascotas: string = this.baseImagesPath + 'mascotas.png';

  //Se setean vaores iniciales
  constructor(private seviceServices: ServiceServices,
    private registroConcursoService: RegistroConcursoService,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private elRef:ElementRef) {
    this.estadoForm = fb.group({
      estadosType: [],
      municipioType: [],
      sucursalType: [],
      direccionType: []
    });
    this.innerHeight = (window.innerHeight);
    this.innerWidth = (window.innerWidth);
    this.imageHorizonte = this.baseImagesPath + 'imgCobertura.png';
    this.imageSatelite = this.baseImagesPath + 'imgCoberturaSat.png';

    this.options = {
      scrollingSpeed: 1000,
      menu: '.menu',
      css3: true,
      navigation: true,
    title:true,
      normalScrollElements: '#modal,#tarifa-tabla',
      onLeave: (origin, destination, direction) => {
        if (origin.anchor === 'Participa' && direction === 'up') {
          this.elRef.nativeElement.querySelector('#cont-capParticipa-nombreParticipante').blur();
          this.elRef.nativeElement.querySelector('#cont-capParticipa-correoParticipante').blur();
        }
        
      },
      autoScrolling:this.booValorAutoScroll,
      anchors: [
        'Inicio', 'Servicio-Express', 'Reenvio-Paquetes',
        'Prepago-Guia', 'Tarifas',
        'Cobertura', 'Sucursales', 'Viva-Aerobus', 'Participa',
        'Contrato-Adhesion', 'Valor-Declarado', 'Quienes-Somos',
        'Contacto'
      ]
    };
  }

  private get window():any {
    return window;
  }

  private get document():any {
    return window.document;
  }

  //Se escucha el evento de onResize
  onResize(event) {
    //event.target.innerWidth // window width
    this.innerHeight = (event.target.innerHeight)
    this.innerWidth = (event.target.innerWidth)
    this.imprimePosicion();
    this.imprimePosicionContrato();
  }

  private setNewResize(){
    this.innerHeight = (window.innerHeight);
    this.innerWidth = (window.innerWidth);

  }

  ngOnInit() {
    this.setInitValuesSucursales();
    /**
     * Se generan los validadores para el formulario.
     */

    this.concursoForm = new FormGroup({
      nombre: new FormControl(),
      correo: new FormControl(),
      imagen: new FormControl()
    });

    this.concursoForm = this.formBuilder.group({
      nombre: [null, Validators.compose(
        [Validators.required,
        Validators.minLength(1),
        Validators.maxLength(80)])],
      correo: [null, Validators.compose(
        [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(51),
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
        imagen: [null, Validators.required]
    });

  }

  ngAfterViewInit(){
    this.innerHeight = (window.innerHeight);
    this.innerWidth = (window.innerWidth);
    setTimeout(() => this.imprimePosicion(), 0);
    setTimeout(() => this.imprimePosicionContrato(), 0);
  }


  private setInitValuesSucursales() {

    this.localizacionMapa.estado = 'Guanajuato';
    this.typeChangedClick();
    this.localizacionMapa.municipio = "León";
    this.typeChangedMunicipioClick();
    this.localizacionMapa.sucursal = "León Central de Autobuses";
    this.typeChangedSucursalClick();
    this.localizacionMapa.direccion = "Blvd. Hilario Medina No. 202 Int. Central de Autobuses";
    this.localizacionMapa.telefono = '477 710 00 60';
    this.localizacionMapa.horario = 'Lunes a Viernes de 9:00 a 17:00 hrs.';
  }

  /****
   * Funciones propias del component de Sucursales.
   *
   */

  public busquedaSucursal(x) {
    const Sucursal = this.objDatos[x].type;

    for (var i = 0; i < this.objSucursal.length; i++) {
      if (Sucursal == this.objSucursal[i].name) {
        this.Municipio = this.objSucursal[i].type;
      }
    }
    for (var i = 0; i < this.objMunicipio.length; i++) {
      if (this.Municipio == this.objMunicipio[i].name) {
        this.Estado = this.objMunicipio[i].type;
      }
    }

    for (var i = 0; i < this.objEstado.length; i++) {
      if (this.Estado == this.objEstado[i].name) {
        this.localizacionMapa.estado = this.Estado;
        this.typeChangedClick();

        this.localizacionMapa.municipio = this.Municipio;
        this.typeChangedMunicipioClick();

        this.localizacionMapa.sucursal = Sucursal;

        this.typeChangedSucursalClick();
      }
    }

  }

  //Datos de Sucursales
  estadosMType = this.seviceServices.agregarEstado();
  allMunicipios = this.seviceServices.agregarMunicipios();
  allSucursales = this.seviceServices.agregarSucursal();
  allDatos = this.seviceServices.agregarDatos();
public siSelecciona:any=null;


  typeChangedClick() {
    const estadosType = this.localizacionMapa.estado;
    this.estadoAfterChangeEvent = this.seviceServices.agregarMunicipioId(estadosType);
    this.municipioAfterChangeEvent = [];
    this.sucursalAfterChangeEvent = [];
  }
  typeChangedMunicipioClick() {
    const municipioType = this.localizacionMapa.municipio;
    this.municipioAfterChangeEvent = this.seviceServices.agregarSucursalId(municipioType);
    this.sucursalAfterChangeEvent = [];
  }
  typeChangedSucursalClick() {
    const sucursalType = this.localizacionMapa.sucursal;
    this.sucursalAfterChangeEvent = this.seviceServices.agregarDatosId(sucursalType);
    for (var i = 0; i < this.allDatos.length; i++) {
      if (this.allDatos[i].type == sucursalType) {
        this.lat = this.allDatos[i].lat;
        this.lng = this.allDatos[i].lng;
      }
    }
  }
  public defaultValue:any;

  typeChanged() {
    const estadosType = this.estadoForm.get('estadosType').value;
    this.estadoAfterChangeEvent = this.seviceServices.agregarMunicipioId(estadosType);
    this.municipioAfterChangeEvent = [];
    this.sucursalAfterChangeEvent = [];
    this.localizacionMapa.municipio = "SELECCIONA";
  }
  typeChangedMunicipio() {
    const municipioType = this.estadoForm.get('municipioType').value;
    this.municipioAfterChangeEvent = this.seviceServices.agregarSucursalId(municipioType);
    this.sucursalAfterChangeEvent = [];
    this.defaultValue="SELECCIONA";
    this.localizacionMapa.sucursal="SELECCIONA";
  }

  typeChangedSucursal() {
    const sucursalType = this.estadoForm.get('sucursalType').value;
    this.sucursalAfterChangeEvent = this.seviceServices.agregarDatosId(sucursalType);
    for (var i = 0; i < this.allDatos.length; i++) {
      if (this.allDatos[i].type == sucursalType) {
        this.lat = this.allDatos[i].lat;
        this.lng = this.allDatos[i].lng;
      }
    }
  }

  /**
   * Terminan funciones del Component Sucursales
   */

  //Funciones para el registro del formulari del concurso
  onChange(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;


  }

  reset() {
    this.myInputFile.nativeElement.value = "";
    this.fileName = null;
  }

  /**
   * Logica del formulario del concurso
   */
  private base64textString: string;
  public fileSize: number;
  public fileExtension: any;
  public fileExtensionError: boolean = false;

  /**
   *
   * @param evt Function para codificar la imagen a Base64
   */
  public handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {//Se valida que no sea null o undefined
      if (file.size < 5*1024*1024) {
        if (this.extensionsValidator(file)) { //Se valida que sea de extensión correcta
          this.fileName = file.name;
          var reader = new FileReader();
          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsBinaryString(file);
        } else {
          this.reset();
        }
      } else {
        alert("EL Archivo es demasiado grande");
        this.reset();
      }
    } else {
      alert("Error al cargar el Archivo");
      this.reset();
    }
  }

  private _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  private extensionsValidator(file) {
    //*-- this function gets content of uploaded file and validation --*/
    var allowedExtensions =
      ["jpg", "jpeg", "png", "JPG", "JPEG","PNG", "JFIF", "BMP", "SVG"];
    this.fileExtension = file.name.split('.').pop();
    if (allowedExtensions.includes(this.fileExtension)) {
      this.fileExtensionError = false;
    } else {
      alert("Error al cargar el Archivo"); 
      this.fileExtensionError = true;
      return false;
    }
    return true;
  }



  /**
   * Se envia los datos del cliente al servicio de Registro en el concurso
   */
  public registrarEnConcurso() {
    this.loading=true;

    this.setNewResize();
    this.datosConcurso.imagen = this.base64textString;
    //se obtiene el arreglo de estatus de las guias consultadas desde el servicio.
    this.registroConcursoService.registroConcurso(this.datosConcurso)
      .then(res => {
        this.loading=false;
        //Se accede al objeto lista de la respuesta.
        if (res && res['resultadoExitoso']===true) {
          this.fileName=null;
          this.base64textString = null;
          this.concursoForm.reset();
          this.visibleAlerta=true;
          this.alertMsgC = this.alertsC.success;

        } else {
          this.fileName=null;
          this.visibleAlerta=true;
          this.alertMsgC = this.alertsC.info;
        }
      })
      .catch(err => {
        this.loading=false;
        this.fileName=null;
        this.visibleAlerta=true;
        this.alertMsgC = this.alertsC.fail;
        this.base64textString = null;
        this.concursoForm.reset();
      });
      this.concursoForm.reset();
      this.base64textString = null;
  }

  //(focus)="focusFunction()" (focusout)="focusOutFunction()
  public focusFunction(){
    this.options.autoScrolling=false;
  }

  private disableAutomaticScroll(){
    this.options.scrollBar=true;
    this.options.keyboardScrolling=false;
    this.options.autoScrolling=false;
  }

  private enableAutomaticScroll(){
    this.options.scrollBar=false;
    this.options.keyboardScrolling=true;
    this.options.autoScrolling=true;
  }

  public onAlertClose(): void {
      this.visibleAlerta = false;
 }

/**
   * Provides read-only equivalent of jQuery's offset function:
   * http://api.jquery.com/offset/
   */
  private offset(nativeEl:any):{width: number, height: number, top: number, left: number} {
    let boundingClientRect = nativeEl.getBoundingClientRect();
    return {
      width: boundingClientRect.width || nativeEl.offsetWidth,
      height: boundingClientRect.height || nativeEl.offsetHeight,
      top: boundingClientRect.top + (this.window.pageYOffset || this.document.documentElement.scrollTop),
      left: boundingClientRect.left + (this.window.pageXOffset || this.document.documentElement.scrollLeft)
    };
  }

  imprimePosicion(){
    //let divTop = this.offset(this.elRef.nativeElement.querySelector('#mascotasTopDiv'));
    //let divTitulo = this.offset(this.elRef.nativeElement.querySelector('#tituloDivMascotas'));
    let divHeader = this.offset(this.elRef.nativeElement.querySelector('#headerDiv'));
    let heightHeader=this.innerHeight/7;
    let topSumDivs=heightHeader;
    this.contenedorImagenHeight=Math.abs(Math.abs(this.innerHeight-topSumDivs));
  }

  imprimePosicionContrato(){
    let divTop = this.offset(this.elRef.nativeElement.querySelector('#contratoTopDiv'));
    let divTitulo = this.offset(this.elRef.nativeElement.querySelector('#tituloContratoDiv'));
    let heightHeader=this.innerHeight/7;
    let topSumDivs=divTop.height+divTitulo.height+heightHeader;
    this.contenedorImagenContratoHeight=Math.abs(Math.abs(this.innerHeight-topSumDivs));
  }


}
