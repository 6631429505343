import { Injectable } from '@angular/core';
//import { Headers, HttpClient, Response, RequestOptions, URLSearchParams, RequestMethod } from '@angular/common/http';
import { HttpHeaders, HttpClient, HttpErrorResponse} from '@angular/common/http';
//Libreria para las promesas, y observables
import {DatosConcurso} from '../entidades/datosconcurso';
import * as rxjs from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class RegistroConcursoService {
  //End Point del servicio REST
    apiURL = environment.CONCURSO_MANDA_GUIA;


    constructor(private http: HttpClient) { }

      registroConcurso(objDatosConcurso: DatosConcurso) {
        //console.log('objDatosConcurso.nombre',objDatosConcurso.imagen);
          let datos = {
            cliente: {
                apellidoMaterno: "NA",
                apellidoPaterno: "NA",
                email: objDatosConcurso.correo,
                id: 0,
                nombre: objDatosConcurso.nombre,
              },
              imagenGuia: objDatosConcurso.imagen
          }

          let httpOptions =  {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': environment.apiKeyAWS
            })
        };

          // let headers = new Headers({ 'Content-Type': 'application/json' });
          // //alert('se envio el siguiente objeto=> '+JSON.stringify(listaGuias));
          // return this.http
          //     .post(this.apiURL, datos, { headers: headers })
          //     .map((response: Response) => response.json())
          //     .toPromise()

          return new Promise((resolve, reject) => {
            this.http.post(this.apiURL, datos, httpOptions).toPromise().then(
              response => {
                resolve(response);
              },(error: HttpErrorResponse) => {
                reject(error);
              }
            );
          });
      }

}
