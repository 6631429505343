import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyLetter]'
})
export class OnlyLetterDirective {

  constructor(private el: ElementRef) { }
  
    @Input() OnlyLetter: boolean;
  
    
    @HostListener('keydown', ['$event']) onKeyDown(event) {
      let e = <KeyboardEvent> event;
      if (this.OnlyLetter) {
        //Se evita el pegado de texto
        if((e.keyCode == 86 && e.ctrlKey === true)){
          e.preventDefault();
        }
        //la ñ es 192
        if ([46, 8, 9, 27, 13,192].indexOf(e.keyCode) !== -1 ||
          // Allow: Ctrl+A
         // (e.keyCode == 65 && e.ctrlKey === true) ||
          // Allow: Ctrl+C
          //(e.keyCode == 67 && e.ctrlKey === true) ||
          // Allow: Ctrl+X
          //(e.keyCode == 88 && e.ctrlKey === true) ||
          //Allow Ctrl+V          
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39) || 
          // Allow space
          (e.keyCode == 32) ||
          //Allow letters A-Z
          ((e.keyCode < 91 && e.keyCode > 64) || (e.keyCode > 96 && e.keyCode < 122)) ||
          (e.shiftKey && (e.keyCode < 91 && e.keyCode > 64)) || (e.shiftKey && (e.keyCode > 96 && e.keyCode < 122))
        )
          {
            // let it happen, don't do anything
            return;
          }
          else{
             // Ensure that it is a number and stop the keypress
            e.preventDefault();
          }          
        }
    }

}
