import { Injectable } from '@angular/core';
//import { Headers, HttpClient, Response, RequestOptions, URLSearchParams, RequestMethod } from '@angular/common/http';
import { HttpHeaders, HttpClient, HttpErrorResponse} from '@angular/common/http';
//Libreria para las promesas, y observables
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { GuiaEntity } from '../entidades/guiaEntity';

@Injectable()
export class GestionGuiasService {

    //End Point del servicio REST
    apiURL = environment.RASTREO_GUIA_SERVICE;

    constructor(private http: HttpClient) { }
    // getRastreoGuia(id) {
        // return this.http.get(this.apiURL)
        //     .map((response: Response) => response.json())
        //     .toPromise();
    // }

    getRastreoGuiasFolioOrigenDestino(listaGuias: GuiaEntity[], seccion: String) {
        let datos = {
          movimientos:"Ultimo", 
          seccion:seccion,
            resumenesGuias:{
              resumenGuia:listaGuias
            }
        }

        // let headers = new Headers({ 'Content-Type': 'application/json' });
        //alert('se envio el siguiente objeto=> '+JSON.stringify(listaGuias));
        // return this.http
        //     .post(this.apiURL, datos, { headers: headers })
        //     .map((response: Response) => response.json())
        //     .toPromise()

        let httpOptions =  {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': environment.apiKeyAWS
            })
        };

        return new Promise((resolve, reject) => {
            this.http.post(this.apiURL, datos, httpOptions).toPromise().then(
                response => {
                    resolve(response);
                },(error: HttpErrorResponse) => {
                    reject(error);
                }
            );
        });

    
    }

    
}
