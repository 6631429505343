export const Mensaje ={ 
    alertasRastreo: {
    success:
    {
      type: 'success',
      msg: `El rastreo de la(s) guía(s) fue exitoso`
    },
    info: 
    {
      type: 'info',
      msg: `La(s) guía(s) no tienen registros en el sistema.`
    },
    fail: 
    {
      type: 'danger',
      msg: `Ha ocurrido un error, favor de intentar nuevamente.`
    }
  },
  alertasConcurso: {
    success:
    {
      type: 'success',
      msg: `¡Te has registrado exitosamente, continúa participando!`
    },
    info: 
    {
      type: 'info',
      msg: `No se pudo realizar el registro, favor de intentar de nuevo.`
    },
    fail: 
    {
      type: 'danger',
      msg: `Ha ocurrido un error, favor de intentar nuevamente.`
    }
  }
}