import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http';

//Libreria para las promesas, y observables
import * as rxjs from 'rxjs';
import { environment } from '../../environments/environment';
import { GuiaEntity } from '../entidades/guiaEntity';
import { Sucursales } from '../helpers/Sucursales';

@Injectable()
export class ServiceServices {
    ReSucursal =Sucursales.allSucursales;
    ReMunicipio=Sucursales.allMunicipios;
    ReDatos=Sucursales.allDatos;
    ReEstado = Sucursales.estadosMType;
    estadosType;
    estado;
    MunicipiosType;
    SucursalType;
    constructor(private http: HttpClient) { }
    agregarSucursal(){
    return this.ReSucursal;
  }
 agregarEstado(){
    return this.ReEstado;
  }
  agregarMunicipios(){
    return this.ReMunicipio;
  }
  agregarDatos(){
      return this.ReDatos;
  }
  agregarMunicipioId(Municipio){
    this.estadosType = this.ReMunicipio.filter(p => p.type == Municipio);
    return  this.estadosType;
}
 agregarSucursalId(Sucursal){
    this.MunicipiosType = this.ReSucursal.filter(s => s.type == Sucursal);
    return  this.MunicipiosType;
}
 agregarDatosId(Datos){
    this.SucursalType = this.ReDatos.filter(d => d.type == Datos);
    return  this.SucursalType;
}

    asignarEstado(nombreEstado){
        this.estado = nombreEstado;
    }

    obtenerEstadoAsignado(){
        return this.estado;
    }

}
