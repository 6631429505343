import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viva-aerobus',
  templateUrl: './viva-aerobus.component.html',
  styleUrls: ['./viva-aerobus.component.css']
})
export class VivaAerobusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
