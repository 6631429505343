/**
 * New typescript file
 */
export class GuiaEntity {

  constructor(
    public id:number=0,
    public folio:number,

    public prefijo: {
         "id": any,
         "abreviatura": any,
         "nombre":any
    },

    public origen: {
          "id": any,
          "abreviatura": any,
          "nombre": any
    },

    public destino: {
          "id": any,
          "abreviatura": any,
          "nombre": any
    })

    {

    }

}
