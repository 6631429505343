import { Component, OnInit } from '@angular/core';
import { PeriodoConcurso } from '../../../../../helpers/periodo_concurso';

@Component({
  selector: 'app-modal-participa',
  templateUrl: './modal-participa.component.html',
  styleUrls: ['./modal-participa.component.css']
})
export class ModalParticipaComponent implements OnInit {

  public currentPeriod:any;
  constructor() { }

  ngOnInit() {
    this.currentPeriod = PeriodoConcurso.PeriodosList[1];
  }

}
