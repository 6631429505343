//Imports del Core de Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
//import { HttpClientModule } from '@angular/common/HttpClientModule';
import { HttpClientModule } from '@angular/common/http';

import { AccordionModule } from 'ngx-bootstrap';
import {FormsModule,ReactiveFormsModule } from '@angular/forms';

//import { CarouselModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap';

//Imports for Modal Windows by Bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';

//Se importa el modulo de Compartido
import { CompartidoModule } from '../compartido/compartido.module';

//Se importan los componentes
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { InicioComponent } from './sections/inicio/inicio.component';
import { NosotrosComponent } from './sections/nosotros/nosotros.component';
import { OnlyNumber } from '../../directivas/only-number.directive';

import { ExpressComponent } from './sections/servicios/express/express.component';
import { ReenvioPaquetesComponent } from './sections/servicios/reenvio-paquetes/reenvio-paquetes.component';
import { GuiasPrepagoComponent } from './sections/servicios/guias-prepago/guias-prepago.component';

//Imports for the loading component
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ModalTarifasComponent } from './sections/modales/modal-tarifas/modal-tarifas.component';
import { ModalParticipaComponent } from './sections/modales/modal-participa/modal-participa.component';
import { ModalDeclaracionesComponent } from './sections/modales/modal-declaraciones/modal-declaraciones.component';
import { ModalPoliticaComponent } from './sections/modales/modal-politica/modal-politica.component';
import { ModalAvisoComponent } from './sections/modales/modal-aviso/modal-aviso.component';
import { ModalCondicionesComponent } from './sections/modales/modal-condiciones/modal-condiciones.component';
import { ModalPreguntasComponent } from './sections/modales/modal-preguntas/modal-preguntas.component';
import { ModalRezagoComponent } from './sections/modales/modal-rezago/modal-rezago.component';
import { ModalReponsiveTarifasComponent } from './sections/modales/modal-reponsive-tarifas/modal-reponsive-tarifas.component';
import { VivaAerobusComponent } from './sections/servicios/viva-aerobus/viva-aerobus.component';



//import { routing } from './app.routing';
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    CompartidoModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
  ],

  declarations: [
    HeaderComponent,
    LoaderComponent,
    InicioComponent,
    NosotrosComponent,
    OnlyNumber,
    ExpressComponent,
    ReenvioPaquetesComponent,
    GuiasPrepagoComponent,
    ModalTarifasComponent,
    ModalParticipaComponent,
    ModalDeclaracionesComponent,
    ModalPoliticaComponent,
    ModalAvisoComponent,
    ModalCondicionesComponent,
    ModalPreguntasComponent,
    ModalRezagoComponent,
    ModalReponsiveTarifasComponent,
    VivaAerobusComponent
  ],
  exports: [
    HeaderComponent,
    LoaderComponent,
    InicioComponent,
    NosotrosComponent,
    ExpressComponent,
    ReenvioPaquetesComponent,
    GuiasPrepagoComponent,
    ModalTarifasComponent,
    ModalParticipaComponent,
    ModalDeclaracionesComponent,
    ModalPoliticaComponent,
    ModalAvisoComponent,
    ModalCondicionesComponent,
    ModalPreguntasComponent,
    ModalRezagoComponent,
    ModalReponsiveTarifasComponent,
    VivaAerobusComponent
  ]
})
export class CoreModule { }
