export const Sucursales = {

  estadosMType: [{ name: 'Guanajuato', id: 1 },
  { name: 'México', id: 2 },
  { name: 'Aguascalientes', id: 3 },
  { name: 'Jalisco', id: 4 },
  { name: 'San Luis Potosí', id: 5 },
  { name: 'Querétaro', id: 6 },
  { name: 'Michoacán', id: 7 },
  { name: 'Colima', id: 8 },
  { name: 'Sinaloa', id: 9 }],

  allMunicipios: [
    { name: 'León', type: 'Guanajuato' },
    { name: 'Arandas', type: 'Jalisco' },
    { name: 'Autlán', type: 'Jalisco' },
    { name: 'Silao', type: 'Guanajuato' },
    { name: 'Pénjamo', type: 'Guanajuato' },
    { name: 'Cortazar', type: 'Guanajuato' },
    { name: 'Dolores', type: 'Guanajuato' },
    { name: 'Irapuato', type: 'Guanajuato' },
    { name: 'Jalostotitlán', type: 'Jalisco' },
    { name: 'Celaya', type: 'Guanajuato' },
    { name: 'Guanajuato', type: 'Guanajuato' },
    { name: 'Moroleón', type: 'Guanajuato' },
    { name: 'Salamanca', type: 'Guanajuato' },
    { name: 'San Felipe', type: 'Guanajuato' },
    { name: 'San Miguel de Allende', type: 'Guanajuato' },
    { name: 'Valle de Santiago', type: 'Guanajuato' },
    { name: 'Aguascalientes', type: 'Aguascalientes' },
    { name: 'Guadalajara', type: 'Jalisco' },
    { name: 'Puerto Vallarta', type: 'Jalisco' },
    { name: 'Lagos De Moreno', type: 'Jalisco' },
    { name: 'La Huerta', type: 'Jalisco' },
    { name: 'Salvatierra', type: 'Guanajuato' },
    { name: 'San Juan De Los Lagos', type: 'Jalisco' },
    { name: 'Zapopan', type: 'Jalisco' },
    { name: 'Tepotzotlán', type: 'México' },
    { name: 'México', type: 'México' },
    { name: 'Toluca', type: 'México' },
    { name: 'Atlacomulco', type: 'México' },
    { name: 'Coapa', type: 'México' },
    { name: 'San Luis Potosí', type: 'San Luis Potosí' },
    { name: 'Querétaro', type: 'Querétaro' },
    { name: 'Cadereyta', type: 'Querétaro' },
    { name: 'Uruapan', type: 'Michoacán' },
    { name: 'La Piedad', type: 'Michoacán' },
    { name: 'Pátzcuaro', type: 'Michoacán' },
    { name: 'Zamora', type: 'Michoacán' },
    { name: 'Morelia', type: 'Michoacán' },
    { name: 'Colima', type: 'Colima' },
    { name: 'Manzanillo', type: 'Colima' },
    { name: 'Tecomán', type: 'Colima' },
    { name: 'Mazatlán', type: 'Sinaloa' }
  ],
  allSucursales: [
    { name: 'SELECCIONA', type: 'INI' },
    { name: 'León Central de Autobuses', type: 'León' },
    { name: 'Arandas Central de Autobuses', type: 'Arandas' },
    { name: 'Autlán Central de Autobuses', type: 'Autlán' },
    { name: 'Silao Central de Autobuses', type: 'Silao' },
    { name: 'Pénjamo Central de Autobuses', type: 'Pénjamo' },
    { name: 'Cortazar Central de Autobuses', type: 'Cortazar' },
    { name: 'Dolores Central de Autobuses', type: 'Dolores' },
    { name: 'Irapuato Central de Autobuses', type: 'Irapuato' },
    { name: 'Jalostotitlán Central de Autobuses', type: 'Jalostotitlán' },
    { name: 'Celaya Central de Autobuses', type: 'Celaya' },
    { name: 'Guanajuato Central de Autobuses', type: 'Guanajuato' },
    { name: 'Moroleón Central de Autobuses', type: 'Moroleón' },
    { name: 'Salamanca Central de Autobuses', type: 'Salamanca' },
    { name: 'San Miguel de Allende Central de Autobuses', type: 'San Miguel de Allende' },
    { name: 'México Central Norte de Autobuses', type: 'México' },
    { name: 'México Central de Autobuses Observatorio', type: 'México' },
    { name: 'México Tepotzotlán Central de Autobuses', type: 'Tepotzotlán' },
    { name: 'México Coapa Central de Autobuses', type: 'Coapa' },
    { name: 'Toluca Central de Autobuses', type: 'Toluca' },
    { name: 'San Luis Potosí Central de Autobuses', type: 'San Luis Potosí' },
    { name: 'Querétaro Central de Autobuses', type: 'Querétaro' },
    { name: 'Querétaro Central de Autobuses Norte', type: 'Querétaro' },
    { name: 'Uruapan Central de Autobuses', type: 'Uruapan' },
    { name: 'La Piedad Central de Autobuses', type: 'La Piedad' },
    { name: 'Zamora Central de Autobuses', type: 'Zamora' },
    { name: 'Morelia Central de Autobuses', type: 'Morelia' },
    { name: 'Guadalajara Central de Autobuses', type: 'Guadalajara' },
    { name: 'Puerto Vallarta Central de Autobuses', type: 'Puerto Vallarta' },
    { name: 'Lagos De Moreno Central de Autobuses', type: 'Lagos De Moreno' },
    { name: 'Salvatierra Central de Autobuses', type: 'Salvatierra' },
    { name: 'San Juan De Los Lagos Central de Autobuses', type: 'San Juan De Los Lagos' },
    { name: 'Zapopan Central de Autobuses', type: 'Zapopan' },
    { name: 'Colima Central de Autobuses', type: 'Colima' },
    { name: 'Manzanillo Central de Autobuses', type: 'Manzanillo' },
    { name: 'Mazatlán Central de Autobuses', type: 'Mazatlán' },
    { name: 'Aguascalientes Central de Autobuses', type: 'Aguascalientes' },
    { name: 'Atlacomulco Central de Autobuses', type: 'Atlacomulco' },
    { name: 'Cadereyta Central de Autobuses', type: 'Cadereyta' },
    { name: 'La Huerta Central de Autobuses', type: 'La Huerta' },
    { name: 'Pátzcuaro Central de Autobuses', type: 'Pátzcuaro' },
    { name: 'San Felipe Central de Autobuses', type: 'San Felipe' },
    { name: 'Tecomán Central de Autobuses', type: 'Tecomán' },
    { name: 'Valle de Santiago Central de Autobuses', type: 'Valle de Santiago' }
  ],
  allDatos: [
    { name: 'Av. De la Convencion Sur 408  Int. Central de  Autobuses', lat: 21.8657099, lng: -102.298478, telefono: '477 710 00 60', horario: 'Abierto las 24 Horas de Lunes a Domingo', type: 'Aguascalientes Central de Autobuses' },
    { name: 'Licenciado Gobernador Medina Ascencio Int. Central de Autobuses', lat: 20.704694,  lng: -102.355865, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Arandas Central de Autobuses' },
    { name: 'Independencia Nacional 1050 Int. Central de Autobuses', lat: 19.773410,  lng: -104.357825, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Autlán Central de Autobuses' },
    { name: 'Av. Antonio Plaza s/n Int. Central de  Autobuses', lat: 20.5139652, lng: -100.8073427, telefono: '477 710 00 60', horario: 'Abierto las 24 Horas de Lunes a Domingo ', type: 'Celaya Central de Autobuses' },
    { name: 'Libramiento Guadalajara - Manzanillo Km. 3, Col. Santa Amalia, Infraestructura Urbana, 28048 Colima Central de Autobuses', lat: 19.2328726, lng: -103.7036629, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs.', type: 'Colima Central de Autobuses' },
    { name: 'Av. Ignacio Aldama No. 404 Interior Central de Autobuses', lat: 20.4845989, lng: -100.9684149, telefono: '477 710 00 60', horario: 'Lunes a Viernes 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs.', type: 'Cortazar Central de Autobuses' },
    { name: 'Av. Hidalgo No. 26-A  Int. Central de Autobuses', lat: 21.155122, lng: -100.9364624, telefono: '477 710 00 60', horario: 'Lunes a Viernes 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs.', type: 'Dolores Central de Autobuses' },
    { name: 'Carretera Guanajuato - Silao No. 450. Central de Autobuses', lat: 20.983, lng: -101.2885248, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs. ', type: 'Guanajuato Central de Autobuses' },
    { name: 'Carr. Fed. Tepic Km 9   Int. Central de Autobuses', lat: 20.6216747, lng: -103.2871682, telefono: '477 710 00 60', horario: 'Abierto las 24 Horas de Lunes a Domingo', type: 'Guadalajara Central de Autobuses' },
    { name: 'Av. 1o. De Mayo s/n  Int. Central de  Autobuses', lat: 20.6764709, lng: -101.3411857, telefono: '477 710 00 60', horario: 'Abierto las 24 Horas de Lunes a Domingo ', type: 'Irapuato Central de Autobuses' },
    { name: 'Tamara No. 1-B  Int. Central de Autobuses', lat: 21.165569, lng: -102.466894, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Jalostotitlán Central de Autobuses' },
    { name: 'Periférico Paseo de la República No. 5555  Interior Central de Autobuses', lat: 20.3476644, lng: -102.0316886, telefono: '477 710 00 60', horario: ' Lunes a Viernes 7:00 a 21:30 hrs. Sábados y Domingos 9:00 a 17:00 hrs.', type: 'La Piedad Central de Autobuses' },
    { name: 'Padre Torres #380 Int. Central de Autobuses', lat: 21.3585223, lng: -101.9238726, telefono: '477 710 00 60', horario: 'Lunes a Sábados de 9:00 a 18:00 hrs. Domingos no hay servicio', type: 'Lagos De Moreno Central de Autobuses' },
    { name: 'Blvd. Hilario Medina No. 202 Int. Central de Autobuses', lat: 21.1209055, lng: -101.6618436, telefono: '477 710 00 60', horario: 'Abierto las 24 hrs. De Lunes a Domingo.', type: 'León Central de Autobuses' },
    { name: 'Foca, Valle de Las Garzas, Planta Peletizadora de Pemex Central de Autobuses', lat: 19.0888899, lng: -104.2901359, telefono: '477 710 00 60', horario: 'Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs.', type: 'Manzanillo Central de Autobuses' },
    { name: 'Internacional México 15, Isla Residencial, Plaza San Ignacio Central de Autobuses', lat: 23.2450094, lng: -106.42117, telefono: '477 710 00 60', horario: '7:00 a 23:00 hrs de Lunes a Sábados y Domingos 9:00 a 17:00 hrs.', type: 'Mazatlán Central de Autobuses' },
    { name: 'Calz. del Hueso 380, Ex-Ejido Sta Úrsula Coapa 27 Bis. Central de Autobuses', lat: 19.3060722, lng: -99.1284885, telefono: '477 710 00 60', horario: 'Lunes a Sábados 7:00 a 21:30 hrs. ', type: 'México Coapa Central de Autobuses' },
    { name: 'Autopista México -Querétaro Km 42.5. Central de Autobuses', lat: 19.7058008, lng: -99.2050734, telefono: '477 710 00 60', horario: ' Lunes a Viernes de 6:00 a 22:00 hrs. Sábados y Domingos de 9:00 a 19:00', type: 'México Tepotzotlán Central de Autobuses' },
    { name: 'Av. 100 Metros No. 4907, Central de Autobuses', lat: 19.4793852, lng: -99.1396004, telefono: '477 710 00 60', horario: 'Abierto las 24 Horas de Lunes a Domingo', type: 'México Central Norte de Autobuses' },
    { name: 'Sur 122 No. 232 Int. Terminal Poniente., Central de Autobuses', lat: 19.3968579, lng: -99.1995506, telefono: '477 710 00 60', horario: 'Lunes a Sábado de 6:00 a 22:00 hrs. Domingos no hay servicio', type: 'México Central de Autobuses Observatorio' },
    { name: 'Carretera Morelia - Zamora Km. 142. Central de Autobuses', lat: 19.7192913, lng: -101.2264413, telefono: '477 710 00 60', horario: 'Abierto las 24 hrs. De Lunes a Domingo', type: 'Morelia Central de Autobuses' },
    { name: 'Prol. las Américas 1620 Int. Central de Autobuses', lat: 20.12239, lng: -101.1794244, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs y Domingo no hay servicio', type: 'Moroleón Central de Autobuses' },
    { name: 'Carretera Irapuato-La Piedad KM. 50. Central de Autobuses', lat: 20.4224651, lng: -101.7154403, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs.', type: 'Pénjamo Central de Autobuses' },
    { name: 'Carr. Fed. Tepic Km 9.5, Las Mojoneras Central de Autobuses', lat: 20.6872157, lng: -105.2310559, telefono: '477 710 00 60', horario: ' Lunes a Sábado 8:00 a 22:00 hrs.', type: 'Puerto Vallarta Central de Autobuses' },
    { name: 'Prol. Luis Vega y Monroy No. 800 Int. Central de Autobuses Edif. A y B', lat: 20.5769949, lng: -100.3585191, telefono: '477 710 00 60', horario: 'Abierto las 24 Horas de Lunes a Domingo', type: 'Querétaro Central de Autobuses' },
    { name: 'Avenida 5 de Febrero 1400, 5 de Febrero. Central de Autobuses', lat: 20.6047989, lng: -100.4166393, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs. ', type: 'Querétaro Central de Autobuses Norte' },
    { name: 'Av. Valle de Santiago No. 2606 Int. Central de Autobuses', lat: 20.5430475, lng: -101.2058622, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs. ', type: 'Salamanca Central de Autobuses' },
    { name: 'Melchor Ocampo No. 1200 Intr. Central de Autobuses', lat: 20.219075, lng: -100.880119, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Salvatierra Central de Autobuses' },
    { name: 'Carr. 57 Km 2.5  Int. Terminal Terrestre Potosína. Central de Autobuses', lat: 22.1465182, lng: -100.9512926, telefono: '477 710 00 60', horario: 'Abierto las 24 Horas de Lunes a Domingo', type: 'San Luis Potosí Central de Autobuses' },
    { name: 'Calle de la Estación No. 90  Int. Central de Autobuses', lat: 20.9146109, lng: -100.7588179, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs. ', type: 'San Miguel de Allende Central de Autobuses' },
    { name: 'Central de Autobuses San Juan de Los Lagos (Luis Donaldo Colosio # 180. Central de Autobuses)', lat: 21.2439856, lng: -102.3248796, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs.', type: 'San Juan De Los Lagos Central de Autobuses' },
    { name: 'Av. Luis H Ducoing No. 105 Intr. Central de Autobuses', lat: 20.9530243, lng: -101.4255723, telefono: '477 710 00 60', horario: 'Lunes a Sábados de 9:00 a 18:00 hrs. Domingos no hay servicio', type: 'Silao Central de Autobuses' },
    { name: 'Felipe Berriozabal No. 101  Int. Central de Autobuses', lat: 19.2779594, lng: -99.6429413, telefono: '477 710 00 60', horario: 'Lunes a Sábado de 7:00 a 22:00 hrs.', type: 'Toluca Central de Autobuses' },
    { name: 'Blvd. Lázaro Cárdenas 555 Int. Central de Autobuses', lat: 19.4265793, lng: -102.042278, telefono: '477 710 00 60', horario: ' Lunes a Viernes 7:00 a 23:00 hrs. Sábados y Domingos 9:00 a 17:00 hrs', type: 'Uruapan Central de Autobuses' },
    { name: 'Carr. Nal Uruapan-Patzcuaro s/n.  Central de Autobuses', lat: 19.9741957, lng: -102.268149, telefono: '477 710 00 60', horario: ' Lunes a Viernes 9:00 a 19:00 hrs. Sábados 9:00 a 17:00 hrs.', type: 'Zamora Central de Autobuses' },
    { name: 'Carretera a Nogales No.1110, San Juan de Ocotán, 45019 Zapopan, Jal. Central de Autobuses', lat: 20.7039424, lng: -103.4606132, telefono: '477 710 00 60', horario: ' Lunes a Sábado 8:00 a 21:00 hrs. Domingos 8:00 a 18:00 hrs.', type: 'Zapopan Central de Autobuses' }, 
    { name: 'Isidro Fabela Norte No.146, Centro, 50450 Atlacomulco, Mex. Central de Autobuses', lat: 19.8046356, lng: -99.8750703, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Atlacomulco Central de Autobuses' },    
    { name: 'Federal 120 S/N, Centro, 76500 Cadereyta, Querétaro Central de Autobuses', lat: 20.7002778, lng: -99.8135484, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Cadereyta Central de Autobuses' },    
    { name: 'Av. Gral. Marcelino García Barragan No.11, La Huerta, 48850 La Huerta, Jalisco Central de Autobuses', lat: 19.4835577, lng: -104.6428548, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'La Huerta Central de Autobuses' },
    { name: 'Lic. Antonio Arriaga Rocha No.2050, Centro, 61600 Pátzcuaro, Michoacán Central de Autobuses', lat: 19.5075080, lng: -101.6185768, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Pátzcuaro Central de Autobuses' },
    { name: 'Carre. a Dolores Km 1 S/N, Zapotes, 37600 San Felipe, Guanajuato Central de Autobuses', lat: 21.4676193, lng: -101.2134876, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'San Felipe Central de Autobuses' },
    { name: 'Progreso No.725, Centro, 28100 Tecomán, Colima Central de Autobuses', lat: 18.9143649, lng: -103.8815953, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Tecomán Central de Autobuses' },
    { name: 'Av. Arteaga No.35, Centro, 38400 Valle de Santiago, Guanajuato Central de Autobuses', lat: 20.4449862, lng: -101.4806436, telefono: '477 710 00 60', horario: 'Lunes a Viernes de 9:00 a 18:00 hrs. Sábados 9:00 a 17:00 hrs. Domingos no hay servicio', type: 'Valle de Santiago Central de Autobuses' }
    
  ]
}
