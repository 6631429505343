import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-declaraciones',
  templateUrl: './modal-declaraciones.component.html',
  styleUrls: ['./modal-declaraciones.component.css']
})
export class ModalDeclaracionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
