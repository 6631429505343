import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/**
 * Imports del Core de Angular
 */
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';
import {HttpClient} from '@angular/common/http';

//Otros Imports (Librerias de Terceros)
//import { CarouselModule } from 'ngx-bootstrap';
import { AccordionModule } from 'ngx-bootstrap';
import { TituloSectionComponent } from '../compartido/titulo-section/titulo-section.component';


@NgModule({
  imports: [
    CommonModule,
    AccordionModule.forRoot()
  ],
  declarations: [
    TituloSectionComponent,
  ],
  exports: [
    TituloSectionComponent,
  ]
})
export class CompartidoModule { }
