import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-rezago',
  templateUrl: './modal-rezago.component.html',
  styleUrls: ['./modal-rezago.component.css']
})
export class ModalRezagoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
