import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-reponsive-tarifas',
  templateUrl: './modal-reponsive-tarifas.component.html',
  styleUrls: ['./modal-reponsive-tarifas.component.css']
})
export class ModalReponsiveTarifasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
