/**
 * Imports del Core de Angular
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
/*import { BrowserAnimationsModule } from '@angular/platform-browser/animations';*/

// ... Modulo de Full Page Scrolling
import { MnFullpageModule } from 'ngx-fullpage';
import { OnlyLetterDirective } from './directivas/only-letter.directive';

//Imports for Modal Windows by Bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import {ReactiveFormsModule } from '@angular/forms';
//import { CarouselModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap';
/**
 * Se importan el modulo del Core y el Componente Principal
 */
import { CoreModule } from './modulos/core/core.module';
import { PrincipalComponent } from './modulos/core/principal/principal.component';
import { CompartidoModule } from './modulos/compartido/compartido.module';

//Imports for Google Maps (Angular Google Maps)
import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations:[
    PrincipalComponent,
    OnlyLetterDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    CompartidoModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    MnFullpageModule.forRoot(), // import module
    AlertModule,

    //API_KEY para Google Maps de la cuenta de Google de primeraplusenvios@gmail.com

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAtNUuhx_5BvRCuqQaLWCr2_pz-MSa7Zc0'
    })
  ],
  providers: [],
  bootstrap: [PrincipalComponent]
})
export class AppModule { }
