import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  baseImagesPath: string='assets/img/';
  imageLogo:string =this.baseImagesPath+'logo_egfa1.png';

constructor() { }

public isCollapsed:boolean = false;

//Control de la barra de menu colapsable
isIn = false;   // store state
   toggleState() { // click handler
       let bool = this.isIn;
       this.isIn = bool === false ? true : false;
   }

}
