// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//Aqui va la URL de Produccón
// const URL_BASE='http://svwd-spring-02:8081/publico/api/v1/enviosprimeraplus/pagina-envios/';
// const URL_BASE='http://svwq-spring-01:8081/publico/api/v1/enviosprimeraplus/pagina-envios/';
// const URL_BASE='http://localhost:8082/publico/api/v1/enviosprimeraplus/pagina-envios/';
// API_Gateway DEV
// const URL_BASE='https://6j6pnvc1xi.execute-api.us-east-1.amazonaws.com/des/publico/api/v1/enviosprimeraplus/pagina-envios/';
// API_Gateway QA
const URL_BASE='https://2w5iicsxnh.execute-api.us-east-1.amazonaws.com/qa/publico/api/v1/enviosprimeraplus/pagina-envios/';


export const environment = {
  production: false,
  // ApiKey DEV
  // apiKeyAWS: "V4BlCTZoAn1YR7wcBNFjF3QCmecxvTv78zbj05ST",
  // ApiKey QA
  apiKeyAWS: "gGoyrPIEiR42ZhqTNbJRh6OMMwxERlAf7MVh8CAu",
  RASTREO_GUIA_SERVICE: URL_BASE + 'obtener-bitacoras-guias',
  CONCURSO_MANDA_GUIA: URL_BASE + 'concurso-manda-guia'
};
