/**
 * New typescript file
 */
export class DatosConcurso {

    
  constructor(
    public nombre:string,
    public correo:string,
    public imagen:string)

    {

    }

}