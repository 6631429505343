import { Component, OnInit,HostListener,ElementRef } from '@angular/core';

@Component({
  selector: 'app-reenvio-paquetes',
  templateUrl: './reenvio-paquetes.component.html',
  styleUrls: ['./reenvio-paquetes.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ReenvioPaquetesComponent implements OnInit {
  //Obtener la altura de la pantalla actual, de cada sección en base a la altura del dispositivo
  innerHeight: any;
  innerWidth: any;
  contenedorImagenHeight:number;

  baseImagesPath: string = 'assets/img/';
  imageReenvio: string = this.baseImagesPath + 'reenvio.png';

  private get window():any {
    return window;
  }

  private get document():any {
    return window.document;
  }

  constructor(private elRef:ElementRef) {
    this.innerHeight = (window.innerHeight);
    this.innerWidth=(window.innerWidth);
    this.contenedorImagenHeight=100;
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.innerHeight = (window.innerHeight);
    this.innerWidth=(window.innerWidth);
    setTimeout(() => this.imprimePosicion(), 0);
  }

  //Se escucha el evento de onResize
  onResize(event) {
    //event.target.innerWidth // window width
    this.innerHeight = (event.target.innerHeight)
    this.innerWidth=(window.innerWidth);
    this.imprimePosicion();
  }

  /**
   * Provides read-only equivalent of jQuery's offset function:
   * http://api.jquery.com/offset/
   */
  private offset(nativeEl:any):{width: number, height: number, top: number, left: number} {
    let boundingClientRect = nativeEl.getBoundingClientRect();
    return {
      width: boundingClientRect.width || nativeEl.offsetWidth,
      height: boundingClientRect.height || nativeEl.offsetHeight,
      top: boundingClientRect.top + (this.window.pageYOffset || this.document.documentElement.scrollTop),
      left: boundingClientRect.left + (this.window.pageXOffset || this.document.documentElement.scrollLeft)
    };
  }

  imprimePosicion(){
    let divTop = this.offset(this.elRef.nativeElement.querySelector('#reenvioTopDiv'));
    let divTitulo = this.offset(this.elRef.nativeElement.querySelector('#tituloDiv'));
    let heightHeader=this.innerHeight/7;
    let topSumDivs=divTop.height+divTitulo.height+heightHeader;
    this.contenedorImagenHeight=Math.abs(Math.abs(this.innerHeight-topSumDivs));
  }
}
