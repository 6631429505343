/**
 * Objeto de Conguracion de los Origenes Destinos de Envíos Primera Plus
 */
export const OrigDestiArray ={
  allOrigDest:[
    {abreviatura:'AGS', nombre:'AGUASCALIENTES'},
    {abreviatura:'ARAN', nombre:'ARANDAS'},
    {abreviatura:'ATLA', nombre:'ATLACOMULCO'},
    {abreviatura:'AUT', nombre:'AUTLÁN'},
    {abreviatura:'CADE', nombre:'CADEREYTA'},
    {abreviatura:'CYA', nombre:'CELAYA'},
    {abreviatura:'COLI', nombre:'COLIMA'},
    {abreviatura:'CORTZ', nombre:'CORTAZAR'},
    {abreviatura:'DOL', nombre:'DOLORES HIDALGO'},
    {abreviatura:'GTO', nombre:'GUANAJUATO'},
    {abreviatura:'GUAD', nombre:'GUADALAJARA'},
    {abreviatura:'RI', nombre:'IRAPUATO'},
    {abreviatura:'JALO', nombre:'JALOSTOTITLÁN'},
    {abreviatura:'HUER', nombre:'LA HUERTA'},
    {abreviatura:'PIED', nombre:'LA PIEDAD'},
    {abreviatura:'LGS', nombre:'LAGOS DE MORENO'},
    {abreviatura:'ON', nombre:'LEÓN'},
    {abreviatura:'MANZ', nombre:'MANZANILLO'},
    {abreviatura:'MAZAT', nombre:'MAZATLÁN'},
    {abreviatura:'COA', nombre:'MÉXICO COAPA'},
    {abreviatura:'TEPO', nombre:'MÉXICO TEPOTZOTLÁN'},
    {abreviatura:'MEXP', nombre:'MÉXICO TERM. OBSERVATORIO'},
    {abreviatura:'MEX', nombre:'MÉXICO TERM. NORTE'},
    {abreviatura:'MORE', nombre:'MORELIA'},
    {abreviatura:'MORO', nombre:'MOROLEÓN'},
    {abreviatura:'PATZ', nombre:'PATZCUARO'},
    {abreviatura:'PENJ', nombre:'PÉNJAMO'},
    {abreviatura:'PTO.V', nombre:'PUERTO VALLARTA'},
    {abreviatura:'QRO', nombre:'QUERÉTARO'},
    {abreviatura:'QROTN', nombre:'QUERÉTARO TERM. NORTE 5 DE FEBRERO'},
    {abreviatura:'SALA', nombre:'SALAMANCA'},
    {abreviatura:'SALV', nombre:'SALVATIERRA'},
    {abreviatura:'SFPE', nombre:'SAN FELIPE, GTO.'},
    {abreviatura:'SJLG', nombre:'SAN JUAN DE LOS LAGOS'},
    {abreviatura:'SLP', nombre:'SAN LUIS POTOSÍ'},
    {abreviatura:'SMA', nombre:'SAN MIGUEL DE ALLENDE'},
    {abreviatura:'SILA', nombre:'SILAO'},
    {abreviatura:'TECO', nombre:'TECOMAN'},
    {abreviatura:'TOL', nombre:'TOLUCA'},
    {abreviatura:'URU', nombre:'URUAPAN'},
    {abreviatura:'VA', nombre:'VALLE DE SANTIAGO'},
    {abreviatura:'ZAM', nombre:'ZAMORA'},
    {abreviatura:'ZAPO', nombre:'ZAPOPAN, JAL.'}
  ]
}